.blog-card{-webkit-box-shadow: 0px 1px 32px -11px rgba(176,173,176,1);
    -moz-box-shadow: 0px 1px 32px -11px rgba(176,173,176,1);
    box-shadow: 0px 1px 32px -11px rgba(176,173,176,1);
margin-bottom: 20px;}
.blog-card-img{height: 300px;}
.blog-card-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.blog-card-details{
    padding: 20px;
    background: #fff;
}
.blog-card-details p{
    text-transform: capitalize;
    line-height: 40px;
   
}
.mobview .blog-card-details p{
 
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}
.blog-card-details a{display: inline-block;
line-height: 16px;
color: var(--theme-color);}

@media only screen and (max-width: 990px){
    .blog-card{
        width: 280px;
    margin-right: 7.5px;
    margin-left: 7.5px;
   }
    
}
