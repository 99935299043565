.delight-txt-card{box-shadow: 0 1px 29px rgba(0,0,0,0.2);
    -webkit-box-shadow:  0 1px 29px rgba(0,0,0,0.2);
    -moz-box-shadow:  0 1px 29px rgba(0,0,0,0.2);
    padding: 50px 90px;
    background: #fff;
    height: 335px;
}
.delight-card-title{text-transform: uppercase;}
.delight-list{padding: 0 20px;
}
.delighlistarea{
    overflow: hidden;
    width: 300px;
    max-height: 120px;
}
.delight-list li{font-size: 14px;
    font-family: 'SharpSans-Medium';}
@media only screen and (max-width: 990px){
    .delight-txt-card{padding: 10px;
    max-height: 170px;}
    .stamp-1-pull{ top: -25px;
        right: 10px;}
        .delight-list li p{font-size: 12px;
        line-height: 18px;
    margin-bottom: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;}
  }
 
 