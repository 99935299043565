.login-title {
  line-height: 40px;
  margin-bottom: 30px !important;
}
.registerform .button {
  padding: 15px 30px;
  font-size: 16px;
  line-height: 16px;
  background-color: var(--theme-color);
  color: #fff;
  font-family: "SharpSans-Medium";
  text-transform: uppercase;
  border: 0;
}
.registerform .button:hover {
  background-color: var(--theme-color);
  color: #fff;
}
.registerform.form .field > label {
  font-size: 16px;
  font-family: "SharpSans-Medium";
  color: #000;
  text-transform: capitalize;
  font-weight: normal;
  margin-bottom: 10px;
  line-height: 16px;
}
.registerform input,
.registerform textarea,
.registerform .checkbox {
  margin-bottom: 30px !important;
  font-size: 16px !important;
  font-family: "SharpSans-Semibold" !important;
  background: transparent !important;
}
.registerform input,
.registerform textarea {
  border: 1px solid #000000ab !important;
}
.registerform textarea {
  resize: none !important;
}
.registerform .checkbox input.hidden + label {
  line-height: 24px !important;
}
.registerform .checkbox label:before {
  top: 5px !important;
}
.errormsgr {
  word-spacing: 0px;
  color: red;
  text-align: center !important;
  font-size: 27px;
  width: 100%;
}
