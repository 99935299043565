.apartmentdetail-area-main {
  background-color: #fff;
  box-shadow: 0 33px 76px 10px rgba(0, 0, 0, 0.07);
  overflow: hidden;
  border-radius: 4px;
}

.checkout-img {
  height: 160px;
}
.checkout-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.apartmentdetail-area {
  padding: 15px;
  margin-bottom: 15px;
}
.hand-pointer {
  cursor: pointer;
}

.chng-btn {
  float: right;
}
.chng-btn a {
  background: #e2e2e2;
  padding: 3px 5px;
  border-radius: 20px;
  transition: all 0.2s ease;
}
.chng-btn a:hover {
  color: var(--theme-color);
}
.apartmentdetail-area p {
  font-size: 14px !important;
  line-height: 16px;
}
.apartment-title {
  font-size: 18px !important;
  line-height: 20px !important;
}
p.date-guest {
  padding-left: 25px;
  position: relative;
  line-height: 28px !important;
}
.date-guest img {
  width: 18px;
  top: 4px;
  left: 0;
  position: absolute;
}
.apartment-type-sel a {
  float: right;
  text-decoration: underline;
}
.apartment-type-sel-chng a {
  text-decoration: underline;
}

.checkout-amenties {
  padding: 0;
  list-style: none;
  margin-bottom: 10px;
}
.checkout-amenties li {
  width: 50%;
  display: inline-block;
}
.mr-10 {
  margin-right: 10px;
}
.in70 {
  display: inline-block;
  width: 60%;
}
.in30 {
  display: inline-block;
  width: 40%;
}
.whole-amount strike {
  margin-right: 15px;
}
.checkout-calculation {
  padding: 0;
  list-style: none;
  margin-bottom: 20px;
}
.checkout-calculation li {
  font-size: 14px;
  line-height: 16px;
  font-family: "SharpSans-Medium";
  color: #242424;
  margin-bottom: 8px;
}
.all-det-btn {
  cursor: pointer;
}
.checkout-calculation .amount {
  float: right;
  display: inline-block;
  width: 30%;
  text-align: right;
}
.view-total-details {
  width: 400px;
  height: 350px;
  overflow: auto;
}
.view-total-details li p {
  color: #fff;
}
.promocode .button {
  width: 35%;
  padding: 14px 30px;
  font-size: 16px;
  line-height: 16px;
  background-color: var(--theme-color);
  color: #fff;
  font-family: "SharpSans-Medium";
  text-transform: uppercase;
  border: 0;
  margin-right: 0 !important;
  border-radius: inherit !important;
}
.promocode input {
  border: 1px solid;
  width: 65%;
  border-right: 0;
  margin-bottom: 15px;
}
.check-total-amnt {
  margin-top: 15px;
  margin-bottom: 15px;
}
.check-total-amnt span {
  float: right;
}

.discountmsg {
  text-align: center;
  color: var(--theme-color);
}
.discountdlt {
  margin-left: 12px;
  cursor: pointer;
}
.view-total-details ul {
  list-style: none;
  padding-left: 0;
}
.view-total-details ul li:first-child {
  margin-bottom: 15px;
}
.view-total-details ul li:last-child {
  margin-top: 15px;
}
.view-total-details ul li {
  margin-bottom: 5px;
}
.view-total-details ul li p span {
  float: right;
}
.view-total-detail-modal .ant-modal-body {
  padding: 20px;
}
.view-total-detail-modal .ant-modal-close {
  background: #fff;
  border: 1px solid;
  border-radius: 50%;
  top: -10px;
  right: -10px;
}
.view-total-detail-modal .ant-modal-close span {
  color: #000;
  width: 20px;
  height: 20px;
  font-size: 11px;
  line-height: 15px;
}
.view-btn {
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  margin-left: 10px;
}
.totalstrip {
  background-color: #000;
  padding: 12px;
  color: #fff !important;
  font-size: 18px !important;
}
/* .tottalbtm {
  background: #000;
  padding: 12px;
} */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .__react_component_tooltip {
    width: 40%;
  }
}
.__react_component_tooltip {
  width: 40%;
}
/* @media screen and (min-width: 840px) {
  .__react_component_tooltip {
    width: 40%;
  }
} */
.leftamenity {
  width: 70%;
  display: inline-block;
}

.rightamenity {
}
.colorgreen {
  color: green;
  font-weight: 800;
}
.btncomplt {
  width: 100%;
}
