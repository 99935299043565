.add-card{max-height: 170px;
position: relative;
margin-bottom: 30px;
border-radius: 10px;
overflow: hidden;
}
.add-card img{height: 100%;
object-fit: cover;}
.add-card .add-text{text-overflow: ellipsis;
    overflow: hidden;
    height: 128px;
    position: absolute;
    width: 80%;
    top: 20px;
    left: 20px;}
.add-card .add-desc{font-size: 16px;
line-height: 18px;}
.add-card .add-title{text-transform: uppercase;
margin-bottom: 10px;}
.listinginput{padding-bottom: 45px;}
