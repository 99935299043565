.bottom-menu{position: fixed;
    background: var(--theme-color);
    bottom: 0;
    z-index: 99}
.bottom-menu-icon{
    margin-bottom: 0;
    padding: 0;
    list-style: none;
}
.bottom-menu-icon li{    -webkit-box-shadow: 0px 0px 6px 2px rgba(166,164,166,1);
    -moz-box-shadow: 0px 0px 6px 2px rgba(166,164,166,1);
    box-shadow: 0px 0px 3px 1px rgb(245, 237, 237);
    width: 35px;
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    height: 35px;
    border-radius: 50%;}
.bottom-menu-icon li i{font-size: 16px;
color: #fff;
line-height: 35px;}
.bottom-menu-icon li a{display: inline-block;}
.fon-icon{transform: rotate(90deg);}