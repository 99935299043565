.apt-det-title{
    text-transform: capitalize ;
}
@media not all and (min-resolution:.001dpcm) {
    @media {
        .apt-det-title {
            word-spacing: -2px;
        }
    }
}
.apt-det-img-gal .owl-nav{position: absolute;
    top: 50px;
    width: 100%;}

@media only screen and (max-width: 990px){
    .apt-det-img-gal .owl-nav{top: 65px;}

}