.apartment-detail-tab .listinginput{padding-bottom: 15px;}
.apartment-detail-search{    border-radius: 10px;
    padding: 15px;
    background: #fff;}
.apt-det-search-dt-pic{padding: 0;}
.apartment-detail-tab .listinginput label{
    font-size: 16px;
}
.apartment-detail-tab .listinginput .ant-calendar-range-picker-input{font-size: 16px;}

.email-link {
    padding-top: 13px !important;
    font-size: 12px;
}

.email-link a {
    color: var(--theme-color);
}
@media only screen and (max-width: 990px){
    .apt-det-search-dt-pic{padding: inherit;}
}

