.location-card {
  position: relative;
  margin-bottom: 30px;
}
.location-card:hover .location-overlay {
  opacity: 1;
}
/* .location-card-img{height: 250px;
    } */
.location-card-img img {
  width: 100%;
  height: 255px;
  object-fit: cover;
}
.city-name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: #fff;
  font-family: "SharpSans-Bold";
}
.location-overlay {
  width: 100%;
  top: 0;
  position: absolute;
  left: 0;

  height: 100%;
  padding: 20px;
  opacity: 0;
  -webkit-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.internal-border {
  height: 100%;
  width: 100%;
  border: 1px solid #fff;
}
.location-link {
  display: block;
}
.location-link:hover {
  text-decoration: none;
}
.internal-border i {
  color: #fff;
  font-size: 24px;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -65%);
}
.city-name-mob {
  display: none;
  text-align: center;
  font-size: 12px;
  color: #222222;
}

@media only screen and (max-width: 1200px) {
  .location-card-img img {
    width: 100%;
    height: 210px;
    object-fit: cover;
  }
}

@media only screen and (max-width: 990px) {
  .location-section {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
    background-image: none;
  }
  .location-card {
    width: 100%;
    margin-bottom: 0;
    border-radius: 50%;
    overflow: hidden;
  }
  .city-name {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .location-overlay {
    padding: 5px;
  }

  .internal-border {
    display: none;
  }
  .location-section h1 {
    display: none;
  }
  .city-name-mob {
    display: block;
  }
  .location-link {
    text-align: center;
    text-decoration: none;
    width: 65px;
    display: block;
    margin-left: 7.5px;
    margin-right: 7.5px;
  }
  .location-card-img img {
    width: 100%;
    height: 65px;
    object-fit: cover;
  }
}
