.header {
  word-spacing: 0;
}

.send-button {
  background-color: var(--theme-color);
  border: 1px solid var(--theme-color);
}

.send-button:disabled {
  background-color: var(--theme-color);
  border: 1px solid var(--theme-color);
}

.question {
  margin-bottom: 0;
  font-size: 1em;
}

.warning {
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.btn-quotation {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
