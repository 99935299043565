.owl-item .item {
  height: 180px;
}
/* .owl-item .item:hover{
    cursor: pointer;
} */
.owl-item .item img {
  height: 100%;
}
.ant-modal {
  width: 700px;
}
.ant-modal .owl-item .item {
  height: 400px;
}
/* .apartment-detail-tab .apt-det-img-gal .owl-nav{
    position: inherit;
    margin-left: auto;
    margin-right: auto;
    top: inherit;
    width: 80px;
} */
.striked-start-price {
  font-size: 34px;
  line-height: 30px;
}
.apar-short-desc {
  font-size: 14px;
  line-height: 22px;
}
.viewgallery {
  padding: 5px 30px;
  font-size: 16px;
  width: 100%;
  line-height: 16px;
  background-color: var(--theme-color);
  color: #fff;
  font-family: "SharpSans-Medium";
  text-transform: uppercase;
  border: 0;
}
.ant-btn-primary {
  display: none;
}
.ant-modal .owl-prev {
  float: left;
  background: #000000b8 !important;
  border-radius: 50% !important;
  width: 30px;
  color: #fff !important;
  height: 30px;
}
.ant-modal .owl-next {
  float: right;
  background: #000000b8 !important;
  border-radius: 50% !important;
  width: 30px;
  color: #fff !important;
  height: 30px;
}
.ant-modal .owl-prev span,
.ant-modal .owl-next span {
  font-size: 30px;
  line-height: 20px;
}
.ant-modal .owl-dots {
  margin-top: 25px;
}
/* .ant-modal  .owl-nav{position: absolute;
        top: 50px;
        width: 100%;} */
.accordion {
  margin-bottom: 15px;
}
.owl-carousel.owl-hidden {
  opacity: inherit !important;
}
.short-text {
  width: 100% !important;
}
.longdecspan p {
  font-size: 14px;
  color: #242424;
  font-family: "SharpSans-Medium";
  margin-bottom: 4px;
  float: left;
}
.long-dec-show p {
  color: #242424;
  font-family: "SharpSans-Medium";
  margin-bottom: 4px;
  float: left;
  font-size: 14px;
}

/* .long-dec-show {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 13ch;
} */
.readmorebtn {
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  color: var(--theme-color) !important;
  display: block;
}
.testimonialtxt {
  color: #242424;
  font-family: "SharpSans-Medium";
  margin-bottom: 4px;
  float: left;
  font-size: 14px;
}
.testemonialtheme .owl-item {
  background: #fff;
  padding: 10px;
  height: 470px;
}
.testimonialtxttitle {
  color: #68bc5b;
  font-family: "SharpSans-Medium";
  margin-bottom: 4px;
  float: left;
  font-size: 14px;
  font-weight: 800;
}

.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 0.5em
}

.resp-sharing-button {
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif
}

.resp-sharing-button__icon svg {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  vertical-align: top
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none
}

.resp-sharing-button--twitter {
  background-color: #55acee
}

.resp-sharing-button--twitter:hover {
  background-color: #2795e9
}

.resp-sharing-button--pinterest {
  background-color: #bd081c
}

.resp-sharing-button--pinterest:hover {
  background-color: #8c0615
}

.resp-sharing-button--facebook {
  background-color: #3b5998
}

.resp-sharing-button--facebook:hover {
  background-color: #2d4373
}

.resp-sharing-button--tumblr {
  background-color: #35465C
}

.resp-sharing-button--tumblr:hover {
  background-color: #222d3c
}

.resp-sharing-button--reddit {
  background-color: #5f99cf
}

.resp-sharing-button--reddit:hover {
  background-color: #3a80c1
}

.resp-sharing-button--google {
  background-color: #dd4b39
}

.resp-sharing-button--google:hover {
  background-color: #c23321
}

.resp-sharing-button--linkedin {
  background-color: #0077b5
}

.resp-sharing-button--linkedin:hover {
  background-color: #046293
}

.resp-sharing-button--email {
  background-color: #777
}

.resp-sharing-button--email:hover {
  background-color: #5e5e5e
}

.resp-sharing-button--xing {
  background-color: #1a7576
}

.resp-sharing-button--xing:hover {
  background-color: #114c4c
}

.resp-sharing-button--whatsapp {
  background-color: #25D366
}

.resp-sharing-button--whatsapp:hover {
  background-color: #1da851
}

.resp-sharing-button--hackernews {
  background-color: #FF6600
}
.resp-sharing-button--hackernews:hover, .resp-sharing-button--hackernews:focus {   background-color: #FB6200 }

.resp-sharing-button--vk {
  background-color: #507299
}

.resp-sharing-button--vk:hover {
  background-color: #43648c
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9;
}

.resp-sharing-button--email {
  background-color: #777777;
  border-color: #777777;
}

.resp-sharing-button--email:hover,
.resp-sharing-button--email:active {
  background-color: #5e5e5e;
  border-color: #5e5e5e;
}

.resp-sharing-button--whatsapp {
  background-color: #25D366;
  border-color: #25D366;
}

.resp-sharing-button--whatsapp:hover,
.resp-sharing-button--whatsapp:active {
  background-color: #1DA851;
  border-color: #1DA851;
}


#single-home-apartment-details {
  padding: 30px;
}


#single-home-listings.deskview.header-slider .owl-stage-outer {
  height: 315px;

}
#single-home-listings.deskview.header-slider .owl-item .item img {
  height: 350px;
}



#single-home-listings.deskview.header-slider .owl-prev, .owl-next {
  width: 15px;
  height: 100px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block !important;
  /*border:0px solid black;*/

}

#single-home-listings.deskview.header-slider .owl-prev {
  left: -35px;
}

#single-home-listings.deskview.header-slider .owl-next {
  right: -35px;
}

#single-home-listings.deskview.header-slider .owl-prev i, .owl-next i {
  transform : scale(3,3); color: var(--theme-color);
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background: none;

}
