.apartment-category-card {
  margin-bottom: 20px;
  height: 500px;
  overflow: hidden;
  -webkit-box-shadow: 0 33px 76px 10px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0 33px 76px 10px rgba(0, 0, 0, 0.07);
  box-shadow: 0 33px 76px 10px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  background: #fff;
}
.font-12 {
  font-size: 12px !important;
}
.font-27 {
  font-size: 27px !important;
}
.apartment-category-card .colored-space {
  width: 100%;
  height: 10px;
}
.apartment-category-card .apart-cat-desc {
  padding: 20px;
}
.apartment-category-card .apart-cat-title {
  text-transform: capitalize;
  margin-bottom: 0px;
  display: grid;
}
.apartment-category-card .apartment-amenities {
  padding: 0;
  list-style: none;
}
.apartment-category-card .apartment-amenities li {
  margin-bottom: 10px;
}
.apartment-category-card .apartment-amenities li span {
  font-size: 16px;
  font-family: "SharpSans-medium";
  color: #242424;
}
.apartment-category-card .apartment-amenities-icon {
  margin-right: 15px;
}
.cat-stnd .apartment-category-card .colored-space,
.cat-stnd .apartment-amenities .apartment-amenities-icon {
  background-color: #efc733;
}
.cat-sup .apartment-category-card .colored-space,
.cat-sup .apartment-amenities .apartment-amenities-icon {
  background-color: #0d47bc;
}
.cat-prem .apartment-category-card .colored-space,
.cat-prem .apartment-amenities .apartment-amenities-icon {
  background-color: var(--theme-color);
}
.cat-stnd .apartment-category-card .apart-cat-title {
  color: #efc733;
}
.cat-sup .apartment-category-card .apart-cat-title {
  color: #0d47bc;
}
.cat-prem .apartment-category-card .apart-cat-title {
  color: var(--theme-color);
}
.cat-stnd .det-amenities-img {
  background-color: #efc733;
}
.cat-sup .det-amenities-img {
  background-color: #0d47bc;
}
.cat-prem .det-amenities-img {
  background-color: var(--theme-color);
}
.fw-600 {
  font-weight: 600;
}
.apartment-amenities .apartment-amenities-icon {
  width: 25px;
  display: inline-block;
  height: 25px;
  text-align: center;
  border-radius: 50%;
}
.apartment-amenities .apartment-amenities-icon img {
  width: 12px;
  margin-bottom: 1px;
}
p.det-amenities {
  padding-left: 30px;
  position: relative;
  line-height: 14px;
}
.det-amenities img {
  width: 14px;
}
.det-amenities-img {
  width: 25px;
  display: inline-block;
  height: 25px;
  text-align: center;
  border-radius: 50%;

  position: absolute;
  left: 0;

  line-height: 19px;
}
@media only screen and (max-width: 990px) {
  .apartment-category-card .apart-cat-title {
    font-size: 20px;
  }
  .apartment-category-card .apartment-amenities li span {
    font-size: 12px;
  }
}

.statititle {
  font-size: 12px;
  line-height: 20px;
  font-style: italic;
  font-weight: normal !important;
}
