.map{
    width: 100%;
    height: 428px;
border: 0;
}
.map-card{
    -webkit-box-shadow: 0 33px 76px rgba(0,0,0,0.07);
    -moz-box-shadow: 0 33px 76px rgba(0,0,0,0.07);
    box-shadow: 0 33px 76px rgba(0,0,0,0.07);
    border-radius:10px;
    overflow: hidden; 
    border: 1px solid #e2e2e2;
}